@font-face {
    font-family: RiverAdventurer;
    src: url(../fonts/river_adventurer.ttf);
}
@font-face {
    font-family: MorthLand;
    src: url(../fonts/morth_land.ttf);
}
html,body,#root {
    height: 100%;
}
body {
    color: #fff;
    background: #000;
    font-size: 14px;
    font-family: MorthLand;
}
img {
    max-width: 100%;
}
a {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}
button.btnCustom {
    background: none;
    padding: 0;
    border: none;
    cursor: pointer;
}
.font-riverAdventurer {
    font-family: RiverAdventurer;
    text-transform: uppercase;
}
.wrap {
    margin: 0 auto;
    min-height: 100%;
    min-width: 1770px;
    max-width: 1920px;
}
.star {
    font-size: 0;
}
.star img {
    display: inline-block;
}
header {
    padding: 0 450px 0 250px;
    position: relative;
    z-index: 2;
}
header .nav-item:not(:last-child) {
    margin-right: 10px;
}
header .nav-link {
    width: 150px;
    height: 105px;
    position: relative;
    color: #FFD591;
    text-align: center;
    font-size: 17px;
    letter-spacing: 1px;
    padding: 23px 5px 10px 5px;
    overflow: hidden;
    cursor: pointer;
    background: url(../img/bg-menu-item.png) no-repeat center top / contain;
}
header .nav-link:hover,
header .nav-link:focus,
header .nav-link.active {
    color: #fff;
}
header .nav-link:before {
    content: "";
    position: absolute;
    left: 0;
    top: -100%;
    width: 100%;
    height: 100%;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    background: url(../img/bg-menu-item-active.png) no-repeat center top / contain;
}
header .nav-link:hover:before,
header .nav-link.active:before {
    top: 0;
}
header .nav-link span {
    position: relative;
    z-index: 1;
}
header .nav-link span.quantity {
    color: #FFBC00;
}
/*========================== MERC CENTER PAGE ==========================*/
#mercCenterPage {
    display: flex;
    flex-direction: column;
    background: url(../img/bg-merc-center.jpg) no-repeat center center / cover;
}
#mercCenterPage .mystery {
    width: 300px;
}
#mercCenterPage .signSuccess {
    position: relative;
    width: 300px;
    height: 400px;
    padding-top: 45px;
    text-align: center;
    background: url(../img/char-frame.png) no-repeat center center / cover;
}
#mercCenterPage .signSuccess:before {
    content: "";
    width: 105px;
    height: 117px;
    position: absolute;
    left: -15px;
    top: -60px;
    background: url(../img/flame.png) no-repeat center center / cover;
}
#mercCenterPage .signSuccess .character {
    width: 200px;
}
#mercCenterPage .signSuccess .info {
    color: #873800;
    font-size: 23px;
    text-align: left;
    padding: 12px 30px 0 120px;
    line-height: 1;
}
#mercCenterPage .signSuccess .info .star {
    margin-top: 3px;
}
#mercCenterPage .signSuccess .info .star img {
    width: 18px;
    margin-right: 2px;
}
#mercCenterPage .signSuccess .element {
    position: absolute;
    left: 35px;
    bottom: 15px;
    width: 73px;
}
#mercCenterPage .totalContract {
    width: 350px;
    height: 185px;
    color: #873800;
    text-align: center;
    font-size: 28px;
    line-height: 1.2;
    padding: 20px 0 0 20px;
    background: url(../img/bg-total-contract.png) no-repeat center center / cover;
}
#mercCenterPage .totalContract .text {
    display: block;
    font-size: 20px;
}
#mercCenterPage .buyContract .number {
    font-size: 40px;
    margin-right: 5px;
    position: relative;
    top: 8px;
}
#mercCenterPage .buyContract .text {
    display: block;
    font-size: 20px;
}
#mercCenterPage .buyContract .icon {
    width: 55px;
}
#mercCenterPage .buyContract .btnBuy {
    height: 80px;
    margin-left: 25px;
}
#mercCenterPage .signContract {
    margin-left: 10px;
}
#mercCenterPage .withDrawText {
    text-align: center;
}
/*========================== BATTLEFIELD PAGE ==========================*/
#battleFieldPage {
    display: flex;
    flex-direction: column;
    background: url(../img/bg-battle-field.jpg) no-repeat center center / cover;
}
#battleFieldPage #content {
    margin-top: 180px;
    padding: 0 10px;
}
#battleFieldPage .battleFieldFrame {
    width: 1720px;
    height: 770px;
    margin: 0 auto;
    position: relative;
    padding: 0 160px;
    background: url(../img/battle-field-frame.png) no-repeat center center / cover;
}
#battleFieldPage .yourTeam {
    position: absolute;
    padding-top: 195px;
    left: 50%;
    transform: translateX(-50%);
    top: -190px;
    width: 600px;
    height: 337px;
    background: url(../img/your-team-frame.png) no-repeat center center;
    background-size: 437px 337px;
    z-index: 1;
}
#battleFieldPage .yourTeam .merc {
    position: relative;
    width: 110px;
    height: 155px;
    padding: 10px 8px 0 12px;
    margin: 0 6px;
    cursor: pointer;
    border-radius: 7px;
    background: url(../img/char-thumb-frame.png) no-repeat center center / cover;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#battleFieldPage .yourTeam .merc .text {
    text-align: center;
    color: #fff;
    font-size: 10px;
    padding: 5px 0;
    line-height: 1;
    position: relative;
    top: -5px;
    background: url(../img/bg-text-selected.png) no-repeat center center / 90px 26px;
}
#battleFieldPage .yourTeam .merc:hover,
#battleFieldPage .yourTeam .merc.selected {
    transform: scale(1.2);
    margin: 0 15px;
    box-shadow: 0px 0px 8px #cca864;
}
#battleFieldPage .yourTeam .merc .element {
    position: absolute;
    width: 30px;
    left: 0;
    top: 0;
    box-shadow: 0 0 0 2px #ffc53d;
    border-radius: 6px;
    z-index: 1;
}
#battleFieldPage .yourTeam .merc .thumbMercWrap {
    width: 100%;
    height: calc(100% - 30px);
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}
#battleFieldPage .yourTeam .merc .thumbMercWrap img {
    width: 115px;
    max-width: initial;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 8px;
}
#battleFieldPage .yourTeam .merc .star {
    text-align: center;
    padding: 6px 0;
    background: url(../img/gradient-rectangle-small.png) no-repeat center center / cover;
}
#battleFieldPage .yourTeam .merc .star img {
    width: 12px;
}
#battleFieldPage .mercenary {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 75px;
    height: 185px;
    padding: 0 0 15px;
    border-radius: 0 0 20px 20px;
    width: 600px;
    background: url(../img/bg-mercenary.png) no-repeat center bottom / cover;
}
#battleFieldPage .mercenary .element {
    width: 40px;
    margin: 0 8px;
    box-shadow: 0 0 0 2px #fff;
    border-radius: 6px;
}
#battleFieldPage .mercenary .arrow-left {
    transform: rotateY(180deg);
}
#battleFieldPage .winingRate {
    color: #E87F45;
    font-size: 20px;
    margin-bottom: 60px;
}
#battleFieldPage .winingRate img {
    width: 270px;
}
#battleFieldPage .winingRate .text {
    margin-top: 10px;
}
#battleFieldPage .fightFrame {
    width: 270px;
    height: 185px;
    text-align: center;
    background: url(../img/fight-frame.png) no-repeat center center / cover;
}
#battleFieldPage .fightFrame .btnFight img {
    width: 130px;
    position: relative;
    top: 20px;
    cursor: pointer;
    animation: glow 3s ease infinite;
}
@keyframes glow {
    0% {
        transform: scale(.9);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(.9);
    }
}
#battleFieldPage .mainImg {
    width: 320px;
    margin-bottom: 40px;
}
#battleFieldPage .mercTeam .charId {
    width: 320px;
    height: 75px;
    position: relative;
    margin: 180px 0 10px;
    color: #612500;
    padding-left: 125px;
    font-size: 32px;
    line-height: 60px;
    background: url(../img/id-bar.png) no-repeat center center / cover;
}
#battleFieldPage .mercTeam .charId .element {
    width: 90px;
    position: absolute;
    left: 15px;
    top: -28px;
}
#battleFieldPage .mercTeam .charInfo {
    width: 190px;
}
#battleFieldPage .mercTeam .charInfo > div:not(:last-child) {
    margin-bottom: 20px;
}
#battleFieldPage .mercTeam .charInfo .text {
    font-size: 15px;
    margin-bottom: 10px;
}
#battleFieldPage .processBar {
    height: 32px;
    padding: 4px 0 0 5px;
    background: url(../img/bg-process-bar.png) no-repeat left center / cover;
}
#battleFieldPage .processBar .percent {
    height: 20px;
    border-radius: 8px;
    background: url(../img/bg-process-bar-percent.png) no-repeat left center / 177px 20px;
}
#battleFieldPage .criTeam .listCri {
    padding-top: 30px;
    margin: 0 60px;
}
#battleFieldPage .criTeam li {
    width: 100px;
    height: 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#battleFieldPage .criTeam li:not(:last-child) {
    margin-bottom: 20px;
}
#battleFieldPage .criTeam li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: .7;
    background: url(../img/cri-thumb-frame.png) no-repeat center center / cover;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#battleFieldPage .criTeam li:hover,
#battleFieldPage .criTeam li.selected {
    transform: scale(1.2);
}
#battleFieldPage .criTeam li:hover:before,
#battleFieldPage .criTeam li.selected:before {
    opacity: 1;
}
#battleFieldPage .criTeam .thumbCriWrap {
    position: relative;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    overflow: hidden;
    border-radius: 18px;
    z-index: 1;
}
#battleFieldPage .criTeam .thumbCriWrap img {
    width: 140px;
    max-width: initial;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -20px;
}
#battleFieldPage .criTeam .element {
    position: absolute;
    width: 30px;
    top: -6px;
    left: -10px;
    box-shadow: 0 0 0 2px #ffc53d;
    border-radius: 6px;
    z-index: 2;
}
#battleFieldPage .criTeam .text {
    text-align: center;
    color: #fff;
    font-size: 10px;
    padding: 5px 0;
    line-height: 1;
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    background: url(../img/bg-text-selected.png) no-repeat center center / 80px 22px;
}
/*========================== MY CREW PAGE ==========================*/
#myCrewPage {
    display: flex;
    flex-direction: column;
    background: #1e0e0e url(../img/bg-my-crew.jpg) no-repeat center center / cover;
}
#myCrewPage .myCrewFrame {
    width: 1720px;
    margin: 0 auto;
}
#myCrewPage .crewBoard {
    width: 730px;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: url(../img/crew-board.png) no-repeat top center / contain;
}
#myCrewPage .crewBoard:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 210px;
    background: url(../img/crew-board-bg-repeat.png) repeat-y center top / contain;
}
#myCrewPage .crewBoard .filterRarity {
    color: #D46B08;
    padding: 28px 75px 30px 75px;
    font-size: 15px;
}
#myCrewPage .crewBoard .filterRarity .item {
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#myCrewPage .crewBoard .filterRarity .item:hover,
#myCrewPage .crewBoard .filterRarity .item.active {
    color: #FFE7BA;
}
#myCrewPage .crewBoard .filterElement {
    position: absolute;
    left: 12px;
    top: 70px;
}
#myCrewPage .crewBoard .filterElement img {
    width: 55px;
    display: block;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 12px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#myCrewPage .crewBoard .filterElement img:first-child {
    border-radius: 50%;
}
#myCrewPage .crewBoard .filterElement img:hover,
#myCrewPage .crewBoard .filterElement img.active {
    box-shadow: 0 0 0 2px #ffc53d;
}
#myCrewPage .crewBoard .listCrew {
    height: 690px;
    margin-left: 85px;
}
#myCrewPage .crewBoard .listCrew .merc {
    margin: 0 10px 40px 10px;
    position: relative;
    height: 234px;
    cursor: pointer;
    border-radius: 10px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#myCrewPage .crewBoard .listCrew .merc:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: url(../img/char-thumb-frame.png) no-repeat center center / cover;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#myCrewPage .crewBoard .listCrew .merc:hover,
#myCrewPage .crewBoard .listCrew .merc.selected {
    box-shadow: 0px 0px 8px #cca864;
}
#myCrewPage .crewBoard .listCrew .merc:hover:before,
#myCrewPage .crewBoard .listCrew .merc.selected:before {
    opacity: 1;
}
#myCrewPage .crewBoard .listCrew .merc:hover .element,
#myCrewPage .crewBoard .listCrew .merc.selected .element {
    box-shadow: 0px 0px 0 2px #fff;
}
#myCrewPage .crewBoard .listCrew .element {
    width: 40px;
    position: absolute;
    left: -12px;
    top: -6px;
    z-index: 1;
    box-shadow: 0 0 0 2px #ffc53d;
    border-radius: 6px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
#myCrewPage .crewBoard .listCrew .buttonAddTeam {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
    width: 80px;
    cursor: pointer;
    z-index: 1;
}
#myCrewPage .crewBoard .listCrew .thumbMercWrap {
    position: relative;
    width: 150px;
    margin: 0 auto;
    height: 135px;
    overflow: hidden;
    border-radius: 12px;
    top: 5px;
}
#myCrewPage .crewBoard .listCrew .thumbMercWrap img {
    width: 170px;
    max-width: initial;
    margin-left: 50%;
    transform: translateX(-50%);
}
#myCrewPage .crewBoard .listCrew .mercOverview {
    position: relative;
}
#myCrewPage .crewBoard .listCrew .info {
    text-align: center;
    line-height: 1;
    padding: 10px 5px 26px;
    border-radius: 0 0 10px 10px;
    width: 158px;
    margin-left: 5px;
    background: url(../img/gradient-rectangle.png) center top;
}
#myCrewPage .crewBoard .listCrew .info > div {
    margin: 5px 0;
}
#myCrewPage .crewBoard .listCrew .star img {
    width: 15px;
}
#myCrewPage .mercFullInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 70px;
    width: 520px;
}
#myCrewPage .mercFullInfo .charId {
    width: 191px;
    height: 45px;
    position: relative;
    color: #612500;
    font-size: 20px;
    padding: 3px 0 0 70px;
    background: url(../img/id-bar.png) no-repeat center center / cover;
}
#myCrewPage .mercFullInfo .charId .element {
    width: 50px;
    position: absolute;
    left: 10px;
    top: -15px;
}
#myCrewPage .mercFullInfo .star {
    margin: 10px 0;
}
#myCrewPage .mercFullInfo .star img {
    width: 27px;
}
#myCrewPage .mercFullInfo .mercPortrait {
    width: 380px;
    margin-bottom: 30px;
    position: relative;
    top: 10px;
}
#myCrewPage .mercFullInfo .charStamina,
#myCrewPage .mercFullInfo .charXp {
    margin-bottom: 10px;
    width: 200px;
}
#myCrewPage .mercFullInfo .processBar {
    height: 34px;
    padding: 4px 0 0 5px;
    background: url(../img/bg-process-bar.png) no-repeat left center / cover;
}
#myCrewPage .mercFullInfo .processBar .percent {
    height: 20px;
    border-radius: 8px;
    background: url(../img/bg-process-bar-percent.png) no-repeat left center / 188px 20px;
}
#myCrewPage .mercFullInfo .text {
    margin-bottom: 10px;
}
#myCrewPage .selectedTeam {
    width: 470px;
    height: 528px;
    position: relative;
    margin: 90px 0 30px;
    background: url(../img/selected-team-frame.png) no-repeat center top / cover;
}
#myCrewPage .selectedTeam:before {
    content: "";
    position: absolute;
    width: 325px;
    height: 320px;
    left: 0;
    top: -320px;
    left: 50%;
    transform: translateX(-50%);
    background: url(../img/bg-link.png) repeat-y center bottom / contain;
}
#myCrewPage .selectedTeam .imgMyTeam {
    position: absolute;
    width: 350px;
    left: 50%;
    transform: translateX(-50%);
    top: -150px;
}
#myCrewPage .selectedTeam .listMerc {
    position: relative;
    z-index: 1;
    padding: 0 10px;
}
#myCrewPage .selectedTeam .listMerc .merc {
    padding: 15px 15px 30px 15px;
    position: relative;
}
#myCrewPage .selectedTeam .listMerc .element {
    position: absolute;
    width: 55px;
    box-shadow: 0 0 0 2px #ffc53d;
    border-radius: 12px;
    left: -20px;
    top: 3px;
    z-index: 1;
}
#myCrewPage .selectedTeam .listMerc .buttonRemove {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -28px;
    width: 100px;
    z-index: 1;
    cursor: pointer;
}
#myCrewPage .selectedTeam .selectedMercFrame {
    height: 215px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    background: url(../img/selected-merc-frame.png) no-repeat center center / cover;
}
#myCrewPage .selectedTeam .selectedThumbMercWrap {
    height: 200px;
    width: 170px;
    margin-bottom: 8px;
    overflow: hidden;
}
#myCrewPage .selectedTeam .selectedThumbMercWrap img {
    max-width: initial;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 220px;
}
#myCrewPage .selectedTeam .star {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15px;
    width: 150px;
    text-align: center;
    height: 45px;
    padding-top: 8px;
    background: url(../img/gradient-rectangle-small.png) no-repeat center center / 150px 45px;
}
#myCrewPage .selectedTeam .star img {
    width: 15px;
}
#myCrewPage .buttonConfirmTeam {
    width: 190px;
    margin: 30px auto 10px;
    display: block;
    cursor: pointer;
}
#myCrewPage .note {
    color: #FFC069;
    text-align: center;
    font-family: "Helvetica Neue";
}
#myCrewPage .timeout .buttonAddTeam,
#myCrewPage .timeout .buttonRemove,
#myCrewPage .timeout .buttonConfirmTeam {
    filter: grayscale(1);
}
/*========================== MODAL ==========================*/
#buyModal .closeModal {
    position: absolute;
    right: -10px;
    top: 44px;
    width: 50px;
    cursor: pointer;
}
#buyModal .modal-content {
    width: 100%;
    height: 325px;
    border: none;
    color: #FFD591;
    background: url(../img/buy-modal-frame.png) no-repeat center center / cover;
}
#buyModal .modal-body {
    text-align: center;
    padding: 190px 40px 0;
    font-size: 18px;
}
#winModal .winFrame {
    width: 800px;
    height: 520px;
    margin: 0 auto;
    padding: 280px 0 0 0;
    font-size: 18px;
    text-align: center;
    background: url(../img/modal-win.png) no-repeat center center / cover;
}
#winModal .bellyReceived {
    font-size: 50px;
    color: #FFE922;
    margin-top: 15px;
    line-height: 1;
}
#winModal .bellyReceived img {
    width: 60px;
    margin-left: 15px;
}
#winModal .xpReceived {
    color: #fdbe01;
    font-size: 25px;
}
#winModal .closeModal,
#lossModal .lossFrame .closeModal {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
    cursor: pointer;
}
#lossModal .lossFrame {
    width: 800px;
    height: 370px;
    padding-top: 190px;
    font-size: 18px;
    position: relative;
    margin: 0 auto;
    text-align: center;
    background: url(../img/modal-loss.png) no-repeat center center / cover;
}
#commonMessageModal .commonMessageFrame {
    width: 537px;
    height: 280px;
    margin: 0 auto;
    position: relative;
    font-size: 18px;
    padding: 40px;
    text-align: center;
    background: url(../img/common-message-frame.png) no-repeat center center / cover;
}
#commonMessageModal .closeModal {
    position: absolute;
    right: -10px;
    top: -10px;
    width: 50px;
    cursor: pointer;
}
#confirmSelectedTeamModal .confirmSelectedTeamFrame {
    position: relative;
    width: 513px;
    height: 506px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #FFD591;
    background: url(../img/confirm-selected-team-frame.png) no-repeat center center / cover;
}
#confirmSelectedTeamModal .selectedTeam .listMerc {
    position: relative;
    z-index: 1;
    padding: 0 35px;
    margin: 100px 0 20px;
}
#confirmSelectedTeamModal .selectedTeam .listMerc .merc {
    margin: 8px;
    position: relative;
}
#confirmSelectedTeamModal .selectedTeam .listMerc .element {
    position: absolute;
    width: 25px;
    box-shadow: 0 0 0 2px #ffc53d;
    border-radius: 50%;
    left: -10px;
    top: 3px;
    z-index: 1;
}
#confirmSelectedTeamModal .selectedTeam .selectedMercFrame {
    height: 104px;
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
    background: url(../img/selected-merc-frame.png) no-repeat center center / cover;
}
#confirmSelectedTeamModal .selectedTeam .selectedThumbMercWrap {
    height: 92px;
    width: 80px;
    margin-bottom: 8px;
    overflow: hidden;
}
#confirmSelectedTeamModal .selectedTeam .selectedThumbMercWrap img {
    max-width: initial;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 90px;
}
#confirmSelectedTeamModal .selectedTeam .star {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
    width: 90px;
    text-align: center;
    padding: 5px;
    background: url(../img/gradient-rectangle-small.png) no-repeat center center / 90px 21px;
}
#confirmSelectedTeamModal .selectedTeam .star img {
    width: 10px;
}
#confirmSelectedTeamModal .closeModal {
    position: absolute;
    right: -10px;
    top: 40px;
    cursor: pointer;
}
#confirmSelectedTeamModal .goToBattleField {
    position: absolute;
    bottom: -20px;
    width: 230px;
    left: 50%;
    transform: translateX(-50%);
}
#disconnectModal .disconnectModalFrame {
    width: 500px;
    height: 324px;
    margin: 0 auto;
    position: relative;
    font-size: 18px;
    text-align: center;
    background: url(../img/disconnect-modal-frame.png) no-repeat center center / cover;
}
#disconnectModal .disconnectModalFrame .walletAddress {
    color: #FFE7BA;
    margin-top: 60px;
    margin-bottom: 20px;
}
#disconnectModal .disconnectModalFrame .totalBelly {
    font-size: 50px;
    color: #FFFBDD;
}
#disconnectModal .disconnectModalFrame .totalBelly img {
    width: 60px;
}
#disconnectModal .disconnectModalFrame .closeModal {
    position: absolute;
    top: 50px;
    right: -15px;
}
#disconnectModal .disconnectModalFrame .buttonDisconnect {
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
}
#claimBellyModal .modal-body {
    width: 500px;
    height: 324px;
    padding: 150px 40px 0;
    font-size: 18px;
    text-align: center;
    background: url(../img/claim-belly-frame.png) no-repeat center center / cover;
}
#claimBellyModal .buttonClaim {
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
}
#claimBellyModal .closeModal {
    position: absolute;
    top: 50px;
    right: -15px;
}
.modal-content {
    background: none;
    border: none;
}
.modal-body {
    padding: 0;
}
.closeModal {
    cursor: pointer;
}
/*========================== CUSTOM SCROLLBAR ==========================*/
.mCustomScrollbar .mCSB_container {
    padding: 10px 0 0 5px;
}
.mCustomScrollbar .mCSB_inside > .mCSB_container {
    margin: 0;
}
.mCustomScrollbar .mCustomScrollBox {
    overflow-y: hidden;
    overflow-x: auto;
    padding-right: 85px;
}
.mCustomScrollbar .mCSB_scrollTools {
    width: 52px;
    right: 18px;
    top: 20px;
}
.mCustomScrollbar .mCSB_scrollTools .mCSB_dragger {
    height: 52px !important;
}
.mCustomScrollbar .mCSB_scrollTools .mCSB_draggerRail {
    display: none;
}
.mCustomScrollbar .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 52px;
    height: 52px;
    cursor: pointer;
    background: transparent url(../img/icon-scroll.png) no-repeat center center / cover;
}
.mCustomScrollbar .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCustomScrollbar .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: transparent;
}
/*========================== RESPONSIVE ==========================*/
@media (max-width: 1680px) {
    .wrap {
        min-width: initial;
        width: 100%;
        padding: 0 10px;
    }
    header {
        padding-right: 360px;
    }
    #battleFieldPage #content {
        padding: 0;
    }
    #myCrewPage .myCrewFrame {
        width: 1600px;
    }
    #myCrewPage .crewBoard .listCrew {
        height: 700px;
    }
    #myCrewPage .selectedTeam {
        width: 450px;
        height: 506px;
    }
    #myCrewPage .mercFullInfo {
        padding: 0 20px;
        width: 420px;
        margin-top: 30px;
    }
    #myCrewPage .mercFullInfo .mercPortrait {
        width: 330px;
    }
    #myCrewPage .selectedTeam .selectedMercFrame {
        height: 203px;
    }
    #battleFieldPage .battleFieldFrame {
        width: 1600px;
        height: 717px;
        padding: 0;
    }
    #battleFieldPage .mercenary {
        height: 180px;
    }
    #battleFieldPage .yourTeam .merc:hover,
    #battleFieldPage .yourTeam .merc.selected {
        transform: scale(1.1);
    }
    #battleFieldPage .mercTeam .charId {
        width: 270px;
        height: 64px;
        margin: 200px 0 0px 140px;
    }
    #battleFieldPage .mercTeam .charId .element {
        width: 80px;
        left: 12px;
        top: -25px;
    }
    #battleFieldPage .mercTeam .charInfo {
        margin: 10px 20px 0 140px;
    }
    #battleFieldPage .mainImg {
        margin-bottom: 20px;
        width: 300px;
    }
    #battleFieldPage .winingRate {
        margin-bottom: 30px;
    }
    #battleFieldPage .criTeam .listCri {
        margin: 0 200px 0 50px;
    }
}
@media (max-width: 1550px) {
    #myCrewPage .myCrewFrame {
        width: 1520px;
    }
    #myCrewPage .crewBoard {
        width: 720px;
    }
    #myCrewPage .crewBoard .listCrew {
        height: 670px;
    }
    #myCrewPage .crewBoard .listCrew .merc {
        height: 227px;
    }
    #myCrewPage .crewBoard .listCrew .info {
        padding-bottom: 20px;
    }
    #myCrewPage .mercFullInfo {
        width: 370px;
    }
    #myCrewPage .mercFullInfo .mercPortrait {
        width: 310px;
    }
    #myCrewPage .selectedTeam {
        width: 419px;
        height: 483px;
    }
    #myCrewPage .selectedTeam .star {
        height: 40px;
    }
    #myCrewPage .selectedTeam .star img {
        width: 14px;
    }
    #myCrewPage .selectedTeam .selectedThumbMercWrap {
        width: 154px;
        height: 175px;
    }
    #myCrewPage .selectedTeam .selectedThumbMercWrap img {
        width: 170px;
    }
    #myCrewPage .selectedTeam .selectedMercFrame {
        height: 192px;
    }
    #myCrewPage .selectedTeam .listMerc .buttonRemove {
        bottom: -22px;
        width: 90px;
    }
    #battleFieldPage .battleFieldFrame {
        width: 1520px;
        height: 681px;
    }
    #battleFieldPage .mercTeam .charId,
    #battleFieldPage .mercTeam .charInfo {
        margin-left: 120px;
    }
    #battleFieldPage .criTeam .listCri {
        margin: 0 180px 0 50px;
    }
    #battleFieldPage .mainImg {
        width: 280px;
        max-width: initial;
    }
    #battleFieldPage .winingRate img {
        width: 230px;
    }
    #battleFieldPage .fightFrame {
        width: 230px;
        height: 158px;
    }
    #battleFieldPage .mercenary {
        height: 175px;
    }
    #battleFieldPage .mercenary .element {
        width: 35px;
    }
}
/*========================== TOKEN VESTING ==========================*/
#tokenVesting,
#tokenVesting .wrap {
    height: 100vh;
    font-size: 14px;
    background-color: white;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
#tokenVesting h1 {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 30px;
    color: black;
}
#tokenVesting form {
    width: 500px;
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
#tokenVesting .status {
    text-align: center;
    margin-bottom: 30px;
}
#tokenVesting .status img {
    width: 30px;
    margin-right: 3px;
}
#tokenVesting .status .walletAddress {
    margin-bottom: 10px;
    font-weight: 500;
    color: black;
}
#tokenVesting .info {
    text-align: center;
    padding: 0 100px;
    color: black;
}
#tokenVesting .info ul.list-unstyled {
    padding: 5px 0;
}
#tokenVesting .info ul.list-unstyled li {
    margin: 10px 0;
}
#tokenVesting .dropdown button {
    width: 100%;
    display: block;
    text-align: left;
    border: 1px solid #e3e3e3;
    background: none;
    padding: 10px;
    border-radius: 5px;
    position: relative;
}
#tokenVesting .dropdown-toggle::after {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-top-color: #a5a5a5;
}
#tokenVesting .dropdown-menu {
    font-size: 14px;
    width: 100%;
    border-color: #e3e3e3;
    padding: 0;
}
#tokenVesting .dropdown-menu .dropdown-item {
    padding: 10px;
}
#tokenVesting .dropdown-item:focus,
#tokenVesting .dropdown-item:hover {
    background-color: #f9f9f9;
    color: #000;
}
#tokenVesting .dropdown-menu li:not(:last-child) .dropdown-item {
    border-bottom: 1px solid #e7e7e7;
}
#tokenVesting .refresh {
    margin-bottom: 20px;
}
#tokenVesting .btn {
    border-radius: 5px;
    font-weight: 700;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    font-size: 14px;
}
#tokenVesting .btn:focus {
    outline: none;
    box-shadow: none;
}
#tokenVesting .btn.btn-outline {
    padding: 1px;
}
#tokenVesting .btn.btn-outline > span {
    position: relative;
    display: block;
    height: 100%;
    background: #fff;
    padding: 5px 15px;
    border-radius: 3px;
    color: #ff9600;
}
#tokenVesting .btn.btn-outline > span > span {
    color: #ff9600;
    background: linear-gradient(to right, #ffcc33 0%, #ff9600 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#tokenVesting .btn-grad-1 {
    background-image: linear-gradient(to right, #de0300 0%, #ff8100  51%, #de0300  100%);
}
#tokenVesting .btn-grad-2 {
    background-image: linear-gradient(to right, #02AAB0 0%, #1fe8c8  51%, #02AAB0  100%);
}
#tokenVesting .btn-grad-3 {
    background-image: linear-gradient(to right, #ff9600 0%, #ffcc33  51%, #ff9600  100%);
}
#tokenVesting .btn-grad-1:hover,
#tokenVesting .btn-grad-2:hover,
#tokenVesting .btn-grad-3:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
}
